import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import InvitationComponent from './invitation';
import ReportComponent from './report';
import { useReportsAndInvitations } from '../utils';

type MatchParams = {
  candidateId: string;
  reportId: string;
  invitationId: string;
};

const Header: React.FC<{ isScrolled?: boolean }> = ({ isScrolled = false }) => {
  const { reportsAndInvitations, terminalInvitations, reportsLoading } =
    useReportsAndInvitations();
  const allReportsAndInvitations = [
    ...reportsAndInvitations,
    ...terminalInvitations,
  ];

  // Figure out the currently selected report to mark it active
  const reportMatch = useRouteMatch<MatchParams>(
    '/candidates/:candidateId/reports/:reportId',
  );

  // Figure out the currently selected invitation to mark it active
  const invitationMatch = useRouteMatch<MatchParams>(
    '/candidates/:candidateId/invitations/:invitationId',
  );

  const reportId = reportMatch?.params?.reportId;

  if (reportId) {
    return (
      <ReportComponent
        reportId={reportId}
        reportsAndInvitations={reportsAndInvitations}
        isScrolled={isScrolled}
      />
    );
  }

  const invitationId =
    invitationMatch?.params?.invitationId || allReportsAndInvitations[0]?.id; // For case where candidate has no reports and route looks like /candidates/[candidate_id]?

  return (
    <InvitationComponent
      isLoading={reportsLoading}
      invitationId={invitationId}
      reportsAndInvitations={reportsAndInvitations}
      allReportsAndInvitations={allReportsAndInvitations}
      isScrolled={isScrolled}
    />
  );
};
export default Header;
