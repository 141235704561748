export enum ScreeningProductType {
  FEDERAL_CRIMINAL_SEARCH = 'federal_criminal_search',
  COUNTY_CIVIL_SEARCH = 'county_civil_search',
  PROFESSIONAL_REFERENCE_VERIFICATION = 'professional_reference_verification',
  CREDIT_REPORT = 'credit_report',
  FEDERAL_CIVIL_SEARCH = 'federal_civil_search',
  EMPLOYMENT_VERIFICATION = 'employment_verification',
  COUNTY_CRIMINAL_SEARCH = 'county_criminal_search',
  EDUCATION_VERIFICATION = 'education_verification',
  STATE_CRIMINAL_SEARCH = 'state_criminal_search',
  GLOBAL_WATCHLIST_SEARCH = 'global_watchlist_search',
  FACIS_SEARCH = 'facis_search',
  MOTOR_VEHICLE_REPORT = 'motor_vehicle_report',
  PROFESSIONAL_LICENSE_VERIFICATION = 'pro_license_verification',
  PERSONAL_REFERENCE_VERIFICATION = 'personal_reference_verification',
  DRUG_SCREENING = 'drug_screening',
  OCCUPATIONAL_HEALTH_SCREENING = 'occupational_health_screening',
  SSN_TRACE = 'ssn_trace',
  SEX_OFFENDER_SEARCH = 'sex_offender_search',
  NATIONAL_CRIMINAL_SEARCH = 'national_criminal_search',
  INTERNATIONAL_ADVERSE_MEDIA_SEARCH = 'international_adverse_media_search',
  INTERNATIONAL_CRIMINAL_SEARCH = 'international_criminal_search_v2',
  INTERNATIONAL_GLOBAL_WATCHLIST_SEARCH = 'international_global_watchlist_search',
  INTERNATIONAL_EDUCATION_VERIFICATION = 'international_education_verification',
  INTERNATIONAL_EMPLOYMENT_VERIFICATION = 'international_employment_verification',
  INTERNATIONAL_IDENTITY_DOCUMENT_VALIDATION = 'international_identity_document_validation',
  INTERNATIONAL_RIGHT_TO_WORK = 'international_right_to_work',
}

export enum CreditReportStatus {
  DISABLED = 'disabled',
  PENDING = 'pending',
  ENABLED = 'enabled',
}

export enum OccHealthStatus {
  DISABLED = 'disabled',
  PENDING = 'pending',
  ENABLED = 'enabled',
}

export enum DrugTestStatus {
  DISABLED = 'disabled',
  PENDING = 'pending',
  ENABLED = 'enabled',
}

export const ScreeningClassMap: Record<string, ScreeningProductType> = {
  federal_criminal: ScreeningProductType.FEDERAL_CRIMINAL_SEARCH,
  county_civil: ScreeningProductType.COUNTY_CIVIL_SEARCH,
  professional_reference:
    ScreeningProductType.PROFESSIONAL_REFERENCE_VERIFICATION,
  credit_report: ScreeningProductType.CREDIT_REPORT,
  federal_civil: ScreeningProductType.FEDERAL_CIVIL_SEARCH,
  employment: ScreeningProductType.EMPLOYMENT_VERIFICATION,
  dot_employment: ScreeningProductType.EMPLOYMENT_VERIFICATION,
  education: ScreeningProductType.EDUCATION_VERIFICATION,
  global_watchlist: ScreeningProductType.GLOBAL_WATCHLIST_SEARCH,
  facis: ScreeningProductType.FACIS_SEARCH,
  mvr: ScreeningProductType.MOTOR_VEHICLE_REPORT,
  mvr_with_cdlis: ScreeningProductType.MOTOR_VEHICLE_REPORT,
  plv: ScreeningProductType.PROFESSIONAL_LICENSE_VERIFICATION,
  drug_alcohol: ScreeningProductType.DRUG_SCREENING,
  dot_drug_alcohol: ScreeningProductType.DRUG_SCREENING,
  occupational_health: ScreeningProductType.OCCUPATIONAL_HEALTH_SCREENING,
  dot_physical: ScreeningProductType.OCCUPATIONAL_HEALTH_SCREENING,
  ssn_trace: ScreeningProductType.SSN_TRACE,
  sex_offender: ScreeningProductType.SEX_OFFENDER_SEARCH,
  national_criminal: ScreeningProductType.NATIONAL_CRIMINAL_SEARCH,
};
