import React from 'react';
import capitalize from 'lodash/capitalize';
import { M } from '@dashboard-experience/mastodon';
import { getReportStatus, ReportStatuses } from '@dashboard-experience/utils';
import { FieldComponentProps } from '../../types';

const Status: React.FC<FieldComponentProps> = ({
  report,
  invitation,
  statusType,
  testid,
}) => {
  const isInvitation = !!invitation && !report.id;
  const invitationStatus = capitalize(invitation?.status);
  if (isInvitation) {
    return <div data-testid={testid}>{invitationStatus}</div>;
  }

  // @ts-ignore - TODO: Remove comment once the Peport type is updated from Utils
  const reportStatus = getReportStatus(report, statusType);
  const displayCompleteAssessStatus =
    report?.account?.assess_enabled && reportStatus === ReportStatuses.COMPLETE;
  const showIncludesCanceled = report.includes_canceled;

  return (
    <div data-testid={testid}>
      {displayCompleteAssessStatus && capitalize(reportStatus)}
      {!displayCompleteAssessStatus && (
        <M.StatusBadge
          statusType={statusType}
          status={reportStatus}
          style={{ verticalAlign: 'text-bottom' }}
        />
      )}
      {showIncludesCanceled && (
        <M.TooltipDefinition
          align='bottom'
          style={{ marginLeft: '0.5rem' }}
          highlighted={false}
          definition='This report contains canceled items.'
        >
          <M.Icon icon='WarningAlt' />
        </M.TooltipDefinition>
      )}
    </div>
  );
};

export default Status;
