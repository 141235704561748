import React, { useMemo } from 'react';
import { Report as ReportModel } from 'types';
import Container from './container';
import ReportInformation from '../report-information';

type Props = {
  allReportsAndInvitations: ReportModel[];
  invitationId: string;
  isLoading: boolean;
  isScrolled?: boolean;
  reportsAndInvitations: ReportModel[];
};

const Invitation: React.FC<Props> = ({
  allReportsAndInvitations,
  invitationId,
  isLoading,
  isScrolled = false,
  reportsAndInvitations,
}) => {
  const report = useMemo(
    () => allReportsAndInvitations.find(report => report.id === invitationId),
    [allReportsAndInvitations, invitationId],
  );

  return (
    <Container
      reportsAndInvitations={reportsAndInvitations}
      isScrolled={isScrolled}
      report={report}
    >
      <ReportInformation
        isScrolled={isScrolled}
        reportsLoading={isLoading}
        selectedReport={report}
        reports={reportsAndInvitations}
      />
    </Container>
  );
};
export default Invitation;
