import React from 'react';
import capitalize from 'lodash/capitalize';
import {
  useSelectedAssessmentVariant,
  useGetDisplayLabel as useGetAssessmentVariantLabel,
} from 'api/assessmentVariants';
import { FieldComponentProps } from '../../types';

const Adjudication: React.FC<FieldComponentProps> = ({ report, testid }) => {
  const { currentVariant } = useSelectedAssessmentVariant();
  const assessVariantLabel = useGetAssessmentVariantLabel();

  const adjudication = currentVariant
    ? currentVariant.adjudication?.name
    : report.adjudication;

  return (
    <div data-testid={testid}>
      {adjudication ? (
        <>
          {capitalize(adjudication.replaceAll('_', ' '))}
          {currentVariant && assessVariantLabel && (
            <span
              style={{
                marginLeft: '8px',
                color: '#6B7280',
                fontSize: '0.875em',
              }}
            >
              ({assessVariantLabel})
            </span>
          )}
        </>
      ) : (
        'No action'
      )}
    </div>
  );
};

export default Adjudication;
