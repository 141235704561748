import React, { ChangeEvent, useCallback, useState } from 'react';
import { colors, M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import type { GetStartedT } from 'components/BetterOrderExperience';
import { useFlag } from '@dashboard-experience/react-flagr';
import { namespace } from '../../../locales';
import {
  StyledInviteBox,
  StyledInviteComboBox,
  StyledInviteEntryRowContainer,
  StyledInviteFormField,
} from './ManuaInviteEntries.styles';
import { PACKAGE_RECOMMENDATION_SIGNUP } from '../../../../../Flags';

type InviteEntryRowProps = {
  index: number;
  invites?: GetStartedT.Invite[];
  handleRemoveInvite: (id: string) => void;
  handleEmailChange: (e: ChangeEvent<HTMLInputElement>, id: string) => void;
  handleNameChange: (e: ChangeEvent<HTMLInputElement>, id: string) => void;
  handlePhoneChange: (e: ChangeEvent<HTMLInputElement>, id: string) => void;
  handleApplyRolesToAllClick: (checked: boolean, id: string) => void;
  handleRoleChange: (role: GetStartedT.AccountRole, id: string) => void;
  roles: GetStartedT.AccountRole[];
  appliedToAll: boolean;
};

const InviteEntryRow = ({
  index,
  invites = [],
  handleRemoveInvite,
  handleEmailChange,
  handleNameChange,
  handlePhoneChange,
  handleApplyRolesToAllClick,
  handleRoleChange,
  roles,
  appliedToAll,
}: InviteEntryRowProps) => {
  const { t } = useTranslation();
  const basePath = `${namespace}:candidateContactInfo.manual.inviteEntry.columns`;
  const currInvite = invites[index];
  const purchaseRecommendationEnabled =
    useFlag(PACKAGE_RECOMMENDATION_SIGNUP)?.variantKey === 'on';

  const handleCloseClick = useCallback(
    () => handleRemoveInvite(currInvite.id),
    [handleRemoveInvite, currInvite.id],
  );

  const [customRole, setCustomRole] = useState<GetStartedT.AccountRole | null>(
    null,
  );

  const handleRoleSearch = useCallback(
    (role: string) => {
      const isRoleExists = roles.some(r => r.name === role);
      if (role && role.trim() !== '' && !isRoleExists) {
        setCustomRole({
          name: role,
        });
      } else {
        setCustomRole(null);
      }
    },
    [roles],
  );

  const handleApplyToAllClick = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      handleApplyRolesToAllClick(e.target.checked, currInvite.id);
    },
    [currInvite.id, handleApplyRolesToAllClick],
  );

  const itemToString = useCallback((item: any) => item?.name, []);

  const onRoleChange = useCallback(
    ({ selectedItem }: { selectedItem: GetStartedT.AccountRole }) => {
      handleRoleChange(selectedItem, currInvite.id);
    },
    [handleRoleChange, currInvite.id],
  );

  return (
    <StyledInviteEntryRowContainer>
      <StyledInviteFormField
        type='email'
        labelText={t(`${basePath}.email.label`)}
        defaultValue={currInvite.email.value}
        data-dd-privacy='mask'
        data-id={`invite-entry-email-${index}`}
        data-testid={`invite-entry-email-${index}`}
        name='email'
        invalid={!currInvite.email.isValid}
        invalidText={t(`${basePath}.email.errorMsg`)}
        onChange={useCallback(
          (e: React.ChangeEvent<HTMLInputElement>) =>
            handleEmailChange(e, currInvite.id),
          [handleEmailChange, currInvite.id],
        )}
      />
      {purchaseRecommendationEnabled && (
        <StyledInviteBox>
          <StyledInviteComboBox
            titleText='Role'
            id={`invite-entry-role-${index}`}
            data-testid={`invite-entry-role-${index}`}
            items={customRole ? [...roles, customRole] : roles}
            itemToString={itemToString}
            allowCustomValue
            selectedItem={currInvite.role}
            onInputChange={handleRoleSearch}
            className={`${customRole ? 'custom-role' : ''}`}
            onChange={onRoleChange}
          />
          {invites.length > 1 &&
            currInvite.role?.name &&
            currInvite.role.name.length > 0 && (
              <M.Checkbox
                onChange={handleApplyToAllClick}
                id={`invite-entry-role-all-${index}`}
                data-testid={`invite-entry-role-all-${index}`}
                labelText={t(`${basePath}.role.applyToAll`)}
                checked={appliedToAll}
              />
            )}
        </StyledInviteBox>
      )}
      <StyledInviteFormField
        type='name'
        labelText={t(`${basePath}.firstName.label`)}
        defaultValue={currInvite.firstName.value}
        data-dd-privacy='mask'
        id={`invite-entry-name-${index}`}
        data-testid={`invite-entry-name-${index}`}
        name='firstName'
        invalid={!currInvite.firstName.isValid}
        invalidText={t(`${basePath}.firstName.errorMsg`)}
        onChange={useCallback(
          (e: React.ChangeEvent<HTMLInputElement>) =>
            handleNameChange(e, currInvite.id),
          [handleNameChange, currInvite.id],
        )}
      />
      <StyledInviteFormField
        type='phone'
        labelText={t(`${basePath}.phoneNumber.label`)}
        defaultValue={currInvite.phoneNumber.value}
        data-dd-privacy='mask'
        id={`invite-entry-phone-${index}`}
        data-testid={`invite-entry-phone-${index}`}
        name='phoneNumber'
        invalid={!currInvite.phoneNumber.isValid}
        invalidText={t(`${basePath}.phoneNumber.errorMsg`)}
        onChange={useCallback(
          (e: React.ChangeEvent<HTMLInputElement>) =>
            handlePhoneChange(e, currInvite.id),
          [handlePhoneChange, currInvite.id],
        )}
      />
      {invites.length > 1 && (
        <M.Button
          kind='tertiary'
          onClick={handleCloseClick}
          data-testid={`invite-entry-remove-${index}`}
        >
          <M.Icon icon='Close' color={colors.uiGrey400} size={20} />
        </M.Button>
      )}
    </StyledInviteEntryRowContainer>
  );
};

export default InviteEntryRow;
