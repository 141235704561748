import React from 'react';
import { AssessStatuses } from '@dashboard-experience/utils';
import { Report } from 'types';
import {
  useGetDisplayLabel as useGetAssessmentVariantLabel,
  useSelectedAssessmentVariant,
} from 'api/assessmentVariants';
import { useReportInvitationStatusDecision } from '../utils';
import {
  AdjudicationLabel,
  AssessmentVariantLabel,
  DotDivider,
  PackageLabel,
} from './report-invitation-labels';
import ReportInvitationDateStatus from './report-invitation-date-status';

type Props = {
  report?: Report;
};

export const isCompleteStatus = (status: string) => {
  const completeStatuses: AssessStatuses[] = [
    AssessStatuses.CLEAR,
    AssessStatuses.CONSIDER,
    AssessStatuses.ELIGIBLE,
    AssessStatuses.ESCALATED,
    AssessStatuses.REVIEW,
  ];

  if (Object.values(AssessStatuses).includes(status as AssessStatuses)) {
    return completeStatuses.includes(status as AssessStatuses);
  }
  return false;
};

const ReportInvitationInformationExpanded: React.FC<Props> = ({ report }) => {
  // @ts-ignore TODO: Remove comment once the Report type is consumed from Utils
  const status = useReportInvitationStatusDecision(report);

  const assessVariantLabel = useGetAssessmentVariantLabel();
  const { currentVariant, isLoading } = useSelectedAssessmentVariant();

  if (!report) {
    return null;
  }

  const isReport = report.object === 'report';
  const adjudication =
    (currentVariant
      ? currentVariant.adjudication?.name
      : report.adjudication) || '';

  return (
    <>
      {isReport ? (
        <>
          <ReportInvitationDateStatus report={report} />
          <DotDivider />
          <PackageLabel pkg={report.package_name || ''} />

          {assessVariantLabel && (
            <>
              <DotDivider />
              <AssessmentVariantLabel label={assessVariantLabel} />
            </>
          )}

          {!isLoading && isCompleteStatus(status) && (
            <>
              <DotDivider />
              <AdjudicationLabel adjudication={adjudication} />
            </>
          )}
        </>
      ) : (
        // Invitations
        <>
          <ReportInvitationDateStatus report={report} />
          <DotDivider />
          <PackageLabel pkg={report.package_name || ''} />
        </>
      )}
    </>
  );
};

export default ReportInvitationInformationExpanded;
