import React, { useState, useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import {
  PURCHASE_RECOMMENDATION_ORDERING_EVENT_NAMES,
  useTrackEvent,
} from 'utils';
import RoleDefinitionSection from '../../../Signup/ui/PurchaseRecommendation/RoleDefinitionSection';
import closeIcon from '../../assets/close.svg';
import {
  Modal,
  CloseButton,
  ButtonGroup,
  StyledTitleWrap,
  StyledSubTitle,
  StyledModalFooter,
  ModalContentContainer,
  LoadingState,
  SubmitButton,
  SubmitButtonText,
} from '../../../Signup/ui/PurchaseRecommendation/PurchaseRecommendationModal.styles';
import { namespace } from '../../../Signup/locales';
import useBatchCreateJobRoles from '../../../Signup/hooks/usePostJobRoles';
import usePatchJobRole from '../../../Signup/hooks/usePatchJobRoles';

type ModalProps = {
  closeModal: () => void;
  modalOpen: boolean;
  role?: { name: string; id: string };
  setSelectedRolesAttr: (attributes: number[]) => void;
  setSelectedRole: (role: { name: string; id: string }) => void;
};

const RoleDefinitionModal: React.FC<ModalProps> = ({
  closeModal,
  modalOpen,
  role = { name: '', id: '' },
  setSelectedRolesAttr,
  setSelectedRole,
}) => {
  const { t } = useTranslation(namespace, {
    keyPrefix: 'components.PurchaseRecommendations',
  });
  const trackEvent = useTrackEvent();
  const [selectedAttributes, setSelectedAttributes] = useState<number[]>([]);
  const [customRoleName, setCustomRoleName] = useState<string>('');
  const { mutate: createJobRoles, isLoading } = useBatchCreateJobRoles({
    onSuccess: response => {
      const roleId = response.job_roles[0].id;
      const roleName = response.job_roles[0].name;

      setSelectedRolesAttr(selectedAttributes);
      setSelectedRole({ name: roleName, id: roleId });
      setCustomRoleName('');

      closeModal();
    },
  });

  const { mutate: updateJobRole, isLoading: isUpdating } = usePatchJobRole({
    onSuccess: response => {
      const roleId = response.id;
      const roleName = response.name;

      setSelectedRolesAttr(selectedAttributes);
      setSelectedRole({ name: roleName, id: roleId });

      closeModal();
    },
  });

  const handleAttributesUpdate = useCallback(
    (roleName: string, attributes: number[]) => {
      setSelectedAttributes(attributes);
    },
    [],
  );

  const handleCustomRoleNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setCustomRoleName(e.target.value);
    },
    [],
  );

  const handleSubmitClick = useCallback(() => {
    const jobRoles = [
      {
        name: role.name.length > 0 ? role.name : customRoleName,
        attribute_ids: selectedAttributes,
      },
    ];

    if (role.id) {
      updateJobRole({ jobRole: jobRoles[0], jobRoleId: role.id });
    } else {
      createJobRoles(jobRoles);
    }

    trackEvent(PURCHASE_RECOMMENDATION_ORDERING_EVENT_NAMES.NEW_ROLE_DEFINED);
  }, [
    role,
    customRoleName,
    selectedAttributes,
    trackEvent,
    updateJobRole,
    createJobRoles,
  ]);

  return (
    <Modal
      onClose={closeModal}
      open={modalOpen}
      data-testid='role-definition-modal'
      type='large'
      preventCloseOnClickOutside
    >
      <M.ModalBody>
        <ModalContentContainer>
          <StyledTitleWrap>
            <h3>{t('header.orderTitle')}</h3>
            <StyledSubTitle>{t('header.orderSubtitle')}</StyledSubTitle>
          </StyledTitleWrap>
          <RoleDefinitionSection
            data-testid='role-definition-section'
            key={role.id}
            roleName={role.name}
            roleNumber={1}
            onAttributesUpdate={handleAttributesUpdate}
            onCustomRoleNameChange={handleCustomRoleNameChange}
            customRoleName={customRoleName}
            orderFlow
          />
          <CloseButton onClick={closeModal} data-testid='close-modal'>
            <img src={closeIcon} alt='close' />
          </CloseButton>
        </ModalContentContainer>
      </M.ModalBody>
      <StyledModalFooter>
        <ButtonGroup>
          <M.Button kind='secondary' onClick={closeModal}>
            Cancel
          </M.Button>
          <SubmitButton
            data-testid='submit-button'
            kind='primary'
            onClick={handleSubmitClick}
            disabled={
              selectedAttributes.length === 0 ||
              isLoading ||
              isUpdating ||
              !(customRoleName || role.name)
            }
          >
            <SubmitButtonText isLoading={isLoading || isUpdating}>
              {t('roleDefinitionSection.submitButtonText')}
            </SubmitButtonText>
            {(isLoading || isUpdating) && (
              <LoadingState>
                {t('roleDefinitionSection.submitButtonLoadingText')}
              </LoadingState>
            )}
          </SubmitButton>
        </ButtonGroup>
      </StyledModalFooter>
    </Modal>
  );
};

export default RoleDefinitionModal;
