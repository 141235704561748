import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddButtonContainer } from 'components/AddScreenings/shared/ListItemElements';
import { namespace } from 'components/BetterOrderExperience/locales';
import {
  StyledDropdown,
  StyledFlex,
  StyledScreeningContainer,
} from '../../AddonsStep.styles';
import ScreeningSectionItemButton from '../ScreeningSectionItem/ScreeningSectionItemButton';
import { ScreeningProductType } from '../../AddonsStep.enums';
import type { AddonsT } from '../..';
import { ScreeningSectionItem } from '../ScreeningSectionItem';

type Props = {
  id: string;
  onAddClick: (
    screening: AddonsT.ScreeningType,
    price: string,
    additionalProperties?: AddonsT.AdditionalProperties,
  ) => void;
  onRemoveClick: (screening: AddonsT.ScreeningType) => void;
  facisLevel1Price: string;
  facisLevel3Price: string;
  isAdded: boolean;
  isIncluded: boolean;
  screeningSectionId: string;
  recommendedAddOnsList?: Set<string>;
  sectionName: string;
};

const FACISSelect: React.FC<Props> = ({
  id,
  isAdded,
  isIncluded,
  onAddClick,
  onRemoveClick,
  screeningSectionId,
  recommendedAddOnsList,
  sectionName,
  facisLevel1Price,
  facisLevel3Price,
}) => {
  const { t } = useTranslation(namespace, {
    keyPrefix: 'addOns.facis',
  });
  const [selectedOption, setSelectedOption] = useState<number>(0);
  const [selectedPrice, setSelectedPrice] = useState<string>('Price varies');
  const [invalid, setInvalid] = useState<boolean>(false);

  const handleAddClick = useCallback(() => {
    if (selectedOption === 0) {
      setInvalid(true);
      return;
    }

    onAddClick(ScreeningProductType.FACIS_SEARCH, selectedPrice, {
      facis_search: { subtype: selectedOption },
    });
  }, [onAddClick, selectedOption, selectedPrice]);

  const handleRemoveClick = useCallback(() => {
    onRemoveClick(ScreeningProductType.FACIS_SEARCH);
  }, [onRemoveClick]);

  const getLabel = useCallback((item: any) => item.label, []);

  const handleChange = useCallback(
    ({ selectedItem }: any) => {
      setInvalid(false);
      setSelectedPrice(
        selectedItem.id === 1 ? facisLevel1Price : facisLevel3Price,
      );
      setSelectedOption(selectedItem.id);
    },
    [facisLevel1Price, facisLevel3Price],
  );

  return (
    <StyledScreeningContainer flexDirection='column'>
      <StyledFlex>
        <ScreeningSectionItem
          id={id}
          description={t('sectionDescription')}
          advantages={t('advantages', { returnObjects: true })}
          disadvantages={t('considerations', {
            returnObjects: true,
          })}
          price={selectedPrice}
          screeningName={t('sectionName')}
          screeningSubName={t('subName')}
          recommendedAddOnsList={recommendedAddOnsList}
          screeningSectionId={screeningSectionId}
          sectionName={sectionName}
        />

        <AddButtonContainer>
          <ScreeningSectionItemButton
            id={id}
            onAddClick={handleAddClick}
            onRemoveClick={handleRemoveClick}
            added={isAdded}
            disabled={isIncluded || isAdded}
            included={isIncluded}
          />
        </AddButtonContainer>
      </StyledFlex>
      <StyledFlex>
        <StyledDropdown
          data-testid={id}
          label={t('label')}
          items={[
            {
              id: 1,
              label: `${t('facis1.name')} - ${facisLevel1Price}`,
            },
            {
              id: 3,
              label: `${t('facis3.name')} - ${facisLevel3Price}`,
            },
          ]}
          itemToString={getLabel}
          onChange={handleChange}
          disabled={isIncluded || isAdded}
          invalid={invalid}
          invalidText={t('invalidText')}
        />
      </StyledFlex>
    </StyledScreeningContainer>
  );
};

export default FACISSelect;
