import React, { useRef } from 'react';
import { Report as ReportModel } from 'types';
import Name from '../name';
import RightSection from '../right-section';
import { useReportInvitationStatusDecision } from '../utils';
import '../styles.scss';

const getStatusClass = (status: string): string =>
  `candidate-header-status-${status.replace(/\s+|_+/g, '-')}`;

type Props = {
  children: React.ReactNode;
  isScrolled?: boolean;
  reportsAndInvitations: ReportModel[];
  report?: ReportModel;
};

const Container: React.FC<Props> = ({
  children,
  isScrolled = false,
  reportsAndInvitations,
  report,
}) => {
  // @ts-ignore TODO: Remove comment once the Report type is consumed from Utils
  const status = useReportInvitationStatusDecision(report);

  const headerRef = useRef(null);

  return (
    <header
      ref={headerRef}
      className={`candidate-header  ${status && getStatusClass(status)} ${
        isScrolled ? 'candidate-header-shrink' : ''
      }`}
    >
      <div
        className={`candidate-header-left ${
          isScrolled ? 'candidate-header-left-shrink' : ''
        }`}
      >
        <Name isScrolled={isScrolled} />
        {children}
      </div>
      <RightSection reportsAndInvitations={reportsAndInvitations} />
    </header>
  );
};
export default Container;
