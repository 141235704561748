import React from 'react';
import { useReportsWithMultiMvr } from 'api/reports';
import { Report as ReportModel } from 'types';
import Container from './container';
import ReportInformation from '../report-information';

type Props = {
  isScrolled?: boolean;
  reportId: string;
  reportsAndInvitations: ReportModel[];
};

const Report: React.FC<Props> = ({
  isScrolled = false,
  reportId,
  reportsAndInvitations,
}) => {
  const { isLoading, report } = useReportsWithMultiMvr({ reportId });

  return (
    <Container
      reportsAndInvitations={reportsAndInvitations}
      isScrolled={isScrolled}
      report={report}
    >
      <ReportInformation
        isScrolled={isScrolled}
        reportsLoading={isLoading}
        selectedReport={report}
        reports={reportsAndInvitations}
      />
    </Container>
  );
};
export default Report;
