const BASE_PACKAGES = {
  checkrdirect_basic_plus_criminal: {
    price: 2999,
    name: 'Basic',
  },
  checkrdirect_complete_criminal: {
    price: 8999,
    name: 'Complete',
  },
  checkrdirect_essential_criminal: {
    price: 5499,
    name: 'Essential',
  },
  checkrdirect_professional_criminal: {
    price: 7999,
    name: 'Professional',
  },
  checkrdirect_international_basic_plus: {
    price: 3200,
    name: 'International Basic plus',
  },
  checkrdirect_international_professional: {
    price: 10200,
    name: 'International Essential',
  },
  checkrdirect_international_professional_plus: {
    price: 12400,
    name: 'International Professional plus',
  },
};

export default BASE_PACKAGES;
